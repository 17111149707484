<template>
  <div>
    <v-card>
      <v-toolbar color="primary" flat>
        <span class="text-h6 white--text">利用規約</span>
        <v-spacer></v-spacer>
        <v-icon color="white" @click="close">mdi-close</v-icon>
      </v-toolbar>
      <div class="pa-2">
        <v-card-text class="overflow-y-auto" style="max-height: 700px;">
          <DocTerms />
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
const DocTerms = () => import('@/components/DocTerms.vue');

export default {
  components: { DocTerms },
  name: 'DialogTerms',
  data() {
    return {
    }
  },
  methods: {
    close() {
      this.$emit('closeTermsEmit');
    }
  },
}
</script>
